import { FC, ReactNode } from "react";
import FooterComponent from "./footer";
import MenuComponent, { IPropsMenuComponent } from "./menu";
import * as Styled from "./style";

export interface IPropsWrapperNavFooter extends IPropsMenuComponent {
  children: ReactNode;
  showFooter?: boolean;
}

const WrapperNavFooter: FC<IPropsWrapperNavFooter> = ({
  children,
  showFooter = true,
  ...propsMenu
}) => {
  return (
    <Styled.Wrapper>
      <MenuComponent {...propsMenu} />
      <Styled.WrapperChildren>{children}</Styled.WrapperChildren>
      {/* <FooterComponent show={showFooter} /> */}
    </Styled.Wrapper>
  );
};

export default WrapperNavFooter;
