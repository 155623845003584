import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const WrapperMenu = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 20px;
  color: #fff;
  padding-top: 16px;
  z-index: 3;
`;

export const Item = styled.div`
  color: #fff;
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 1.5;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px;

  box-shadow: inset 0 0 0 0 #fff;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: #000;
    box-shadow: inset 200px 0 0 0 #fff;
  }
`;
