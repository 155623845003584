import * as Three from "three";
import { useThree } from "@react-three/fiber";
import { RefObject, useContext, useEffect, useState } from "react";
import { materialClouds } from "../..";
import { GlobalContext } from "../../../../../globalContext";
import { getMinMax } from "../../../../../utils/getMinMax";
import { ISize, useWindowParams } from "../../../../../utils/useWindowParams";
import { getPosRot } from "../../data";
import { getPostionWithScale } from "../getPosition";
import { IDataClounds } from "../useDataClouds/types";
import { TDataGeometry } from "../useGetGeometry";
import { initUFCloudPoints } from "../../../material/clouds";
interface IDataScale {
  uSizePlus: number;
  uSizeMulti: number;
}

const dataScale: IDataScale[] = [
  // { uSizeMulti: 4.5, uSizePlus: 3 },
  // { uSizeMulti: 3, uSizePlus: 1 },
  // { uSizeMulti: 2, uSizePlus: 1 },
  // { uSizeMulti: 1.5, uSizePlus: 0 },
  { uSizeMulti: 1, uSizePlus: 0 },
];

const dataScaleMobile: IDataScale[] = [
  // { uSizeMulti: 5, uSizePlus: 4 },
  // { uSizeMulti: 3, uSizePlus: 2 },
  // { uSizeMulti: 3, uSizePlus: 2 },
  // { uSizeMulti: 2, uSizePlus: 1 },
  { uSizeMulti: 1, uSizePlus: 1 },
];

export const setPostionRotation = (
  refPos: RefObject<Three.Group>,
  refRot: RefObject<Three.Group>,
  size: ISize
) => {
  const { position, rotation } = getPosRot(size);
  if (refRot.current) {
    refRot.current.rotation.set(rotation.x, rotation.y, rotation.z);
  }
  if (refPos.current) {
    refPos.current.position.set(position.x, position.y, position.z);
  }
};

interface IProps {
  changeSpeed: [number, number];
  geometryData: TDataGeometry;
  dataClouds: IDataClounds;
}

export const useSetting = ({ geometryData, dataClouds }: IProps) => {
  const { raycaster } = useThree();
  const { data, onChangeData } = useContext(GlobalContext);
  const { slide } = data;
  const { refRotateGroup, refPositionGroup, refPoints, threshold } = dataClouds;
  const [isInit, setIsInit] = useState(true);

  const size = useWindowParams();

  useEffect(() => {
    if (!isInit) {
      materialClouds.uTransition = 0.00001;
    }
    materialClouds.uMode = slide < materialClouds.uSlide;
  }, [slide]);

  useEffect(() => {
    const dataScalePoints =
      size.width > 768 ? dataScale[slide - 1] : dataScaleMobile[slide - 1];
    materialClouds.uSizeMulti = dataScalePoints.uSizeMulti;
    materialClouds.uSizePlus = dataScalePoints.uSizePlus;
  }, [slide, size]);

  useEffect(() => {
    const uScaleHeight = getMinMax(size.height / 1024 - 0.1, 0, 1);
    const params = raycaster.params.Points;
    if (params) {
      params.threshold = threshold * uScaleHeight;
    }
    materialClouds.uScaleHeight = uScaleHeight;
    setPostionRotation(refPositionGroup, refRotateGroup, size);
  }, [size]);

  useEffect(() => {
    const obj = refPoints.current;
    type TKey = keyof TDataGeometry;
    const dataNameSlide: TKey[] = [
      // "aChain",
      // "aMan",
      // "aWoman",
      // "aPass",
      "aEarth",
    ];
    const position = geometryData[
      dataNameSlide[slide - 1]
    ] as Three.BufferAttribute;

    if (!obj) return;

    obj.geometry.setAttribute(
      "position",
      getPostionWithScale(position, materialClouds.uScaleHeight)
    );
  }, [slide, refPoints, size, geometryData]);

  useEffect(() => {
    setIsInit(false);
    materialClouds.uSlide = slide;
    materialClouds.uOpacity = 0;
  }, []);
};
