import { useContext, useEffect, useId, useState } from "react";

import { dataScroll } from "../../App";
import { GlobalContext } from "../../globalContext";
import { useWindowParams } from "../../utils/useWindowParams";
import WrapperNavFooter from "../../wrappers/wrappperNavFooter";
import { dataSlide } from "./data";
// import SliderComponents from "./slider";
import MainSlideText from "./slideText";
import * as Styled from "./style";

const MainPage = () => {
  const idComponent = useId();
  const { data, onChangeData } = useContext(GlobalContext);
  const { slide, needsUpdate } = data;
  const { width, height } = useWindowParams();

  const [startTouch, setStartTouch] = useState(0);

  useEffect(() => {
    onChangeData("needsUpdate", data.needsUpdate + 1);
  }, [width, height]);

  useEffect(() => {
    const event = (status: boolean) => {
      const { slide } = data;
      const { scrollSlide, isBlockScroll } = dataScroll;
      const sum = slide + scrollSlide;
      let slideChange = scrollSlide;

      if (isBlockScroll) return;
      dataScroll.isBlockScroll = true;
      if (status && sum < dataScroll.countSlides) slideChange++;
      if (!status && sum > 1) slideChange--;
      if (slideChange !== scrollSlide) {
        dataScroll.scrollSlide = slideChange;
        onChangeData("needsUpdate", data.needsUpdate + 1);
      }

      setTimeout(() => {
        dataScroll.isBlockScroll = false;
      }, 1000);
    };

    const eventWheel = ({ deltaY }: WheelEvent) => event(deltaY + 1 > 0);
    const eventTouchStart = ({ changedTouches }: TouchEvent) => {
      const move = startTouch - changedTouches[0].clientY;
      if (Math.abs(move) < 50) return;
      event(move > 0);
    };

    document.addEventListener("wheel", eventWheel);
    document.addEventListener("touchend", eventTouchStart);

    return () => {
      document.removeEventListener("wheel", eventWheel);
      document.removeEventListener("touchend", eventTouchStart);
    };
  }, [data.slide, startTouch, data.needsUpdate]);

  useEffect(() => {
    document.addEventListener("touchstart", ({ changedTouches }) =>
      setStartTouch(changedTouches[0].clientY)
    );
  }, []);

  return (
    <WrapperNavFooter
      data={[{ text: "Contact", to: "/contact" }]}
      showFooter={slide === dataScroll.countSlides}
    >
      <Styled.Wrapper>
        <Styled.WrapperSlider>
          <Styled.WrapperText slide={slide - 1}>
            <div>
              {dataSlide(width).map((item, index) => (
                <MainSlideText key={idComponent + index} {...item} />
              ))}
            </div>
          </Styled.WrapperText>
        </Styled.WrapperSlider>
      </Styled.Wrapper>
    </WrapperNavFooter>
  );
};

export default MainPage;
