import LinkComponent from "../../../components/Link";
import * as Styled from "./style";

const TextNotFound = () => {
  return (
    <Styled.Wrapper>
      <div>
        The Page you are looking for doesn't exist or an other error occurred.
      </div>
      <div>
        <span>Go back or head over to</span>
        <LinkComponent to="/" color="#1c8cb8" margin>
          finnected.com
        </LinkComponent>
        <span>to choose a new direction.</span>
      </div>
    </Styled.Wrapper>
  );
};

export default TextNotFound;
