import styled from "styled-components";

interface IWrapper {
  height?: string;
}

export const Wrapper = styled.div<IWrapper>`
  height: ${({ height }) => height || "initial"};
`;

export const Title = styled.div`
  font-size: 20px;
  color: #fff;
  margin-bottom: 4px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const Error = styled.div`
  font-size: 14px;
  color: #ea0b0b;
  margin-top: 4px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

interface IWrapperField {
  maxHeigth: string;
  isFocus: boolean;
  isError: boolean;
  height?: string;
}

const getBorder = (isFocus: boolean, isError: boolean, isHover: boolean) => {
  if (isError) return "#ea0b0b";
  if (isFocus) return "#53eaea";
  if (isHover) return "#53eaea";
  return "#fff";
};

export const WrapperField = styled.div<IWrapperField>`
  border: 2px solid;
  border-radius: 4px;
  padding: 10px;
  height: ${({ height }) => (height ? "calc(100% - 24px)" : "initial")};
  transition: border-color 0.5s;

  border-color: ${({ isFocus, isError }) => getBorder(isFocus, isError, false)};
  &:hover {
    border-color: ${({ isFocus, isError }) =>
      getBorder(isFocus, isError, true)};
  }

  & > textarea {
    width: 100%;
    height: 100%;
    min-height: 40px;
    max-height: ${({ maxHeigth, height }) => (height ? "unset" : maxHeigth)};
    color: #fff;
    font-size: 16px;
    background-color: transparent;
    border: unset;
    border-radius: 4px;
    box-shadow: unset;
    outline: unset;
    resize: ${({ height }) => (height ? "none" : "vertical")};

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #1d2429;
      border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #2f3a42;
    }
  }
`;
