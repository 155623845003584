import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/button";
import InputComponent from "../../components/input";
import TextareaComponent from "../../components/teaxtarea";
import WrapperGlassPage from "../../wrappers/wrapperGlassPage";
import { intiData, validateData } from "./data";
import * as Styled from "./style";
import ContactPageTextItem from "./textItem";
import { IData } from "./types";

const ressponseSuccess = {
  title: "Thank you for your message",
  subTitle: "We'll get back to you shortly",
};
const ressponseError = {
  title: "Something went wrong",
  subTitle: "Please try again later",
};

const ContactPage = () => {
  const [data, setData] = useState(intiData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState({ title: "", subTitle: "" });

  const navigate = useNavigate();

  const onChangeData = <K extends keyof IData, D extends IData[K]["value"]>(
    key: K,
    value: D
  ) => setData((prev) => ({ ...prev, [key]: { value, error: "" } }));

  const onChangeError = (key: keyof IData, error: string) =>
    setData((prev) => ({ ...prev, [key]: { ...prev[key], error } }));

  const postContactForm = (): Promise<boolean> =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("fullname", data.name.value);
      formData.append("email", data.email.value);
      formData.append("message", data.message.value);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "/sendmail.php", true);
      xhr.onload = function () {
        try {
          const resp = JSON.parse(this.responseText);
          resolve(resp.result === "ok");
        } catch {
          reject(false);
        }
      };
      xhr.onerror = function () {
        reject(false);
      };
      xhr.send(formData);
    });

  const onClickSubmit = async () => {
    const errors = validateData(data);
    errors.forEach(({ key, error }) => onChangeError(key, error));
    const isOkay = !errors.filter(({ error }) => !!error.length).length;

    if (isOkay) {
      try {
        setIsLoading(true);
        const response = await postContactForm();
        setDataResponse(response ? ressponseSuccess : ressponseError);
      } catch {
        setDataResponse(ressponseError);
      } finally {
        setIsSubmit(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <WrapperGlassPage propsMenu={{ data: [{ text: "Home", to: "/" }] }}>
      <Styled.CloseButton onClick={() => navigate("/")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          // className="feather feather-x-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      </Styled.CloseButton>

      <Styled.WrapperThanks isSubmit={isSubmit}>
        <Styled.Title>{dataResponse.title}</Styled.Title>
        <Styled.SubTitle>{dataResponse.subTitle}</Styled.SubTitle>
      </Styled.WrapperThanks>

      <Styled.WrapperForm isSubmit={isSubmit}>
        <Styled.Title>Contact us</Styled.Title>
        <Styled.SubTitle>
          {/* Fill up the form and our Team will get back to you shortly. */}
        </Styled.SubTitle>
        <Styled.WrapperInput>
          <InputComponent
            title="Full Name"
            {...data.name}
            onChange={(value) => onChangeData("name", value)}
          />
          <InputComponent
            title="Email"
            type="email"
            {...data.email}
            onChange={(value) => onChangeData("email", value)}
          />
        </Styled.WrapperInput>
        <TextareaComponent
          title="Message"
          {...data.message}
          height="200px"
          onChange={(value) => onChangeData("message", value)}
        />
        <Styled.WrapperButton>
          <ButtonComponent onClick={onClickSubmit} isLoading={isLoading}>
            Submit
          </ButtonComponent>
        </Styled.WrapperButton>
        {/* <Styled.WrapperText>
          <ContactPageTextItem mobileHide />
          <ContactPageTextItem mobileHide>
            Chaindentity Ltd.
          </ContactPageTextItem>
          <ContactPageTextItem>North Row 64</ContactPageTextItem>
          <ContactPageTextItem>London</ContactPageTextItem>
          <ContactPageTextItem mobileHide>United Kingdom</ContactPageTextItem>
        </Styled.WrapperText> */}
      </Styled.WrapperForm>
    </WrapperGlassPage>
  );
};

export default ContactPage;
