import { lazy, Suspense, useContext, useEffect, useState } from "react";
// import CanvasApp from "./components/canvas"
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { materialClouds } from "./components/canvas/clouds";
import {
  GlobalContext,
  IDataScroll,
  initDataGlobalContext,
  TChangeGlobalContext,
} from "./globalContext";
import ContactPage from "./pages/contact";
import ErrorPage from "./pages/error";
import TextNotFound from "./pages/error/textNotFound";
import MainPage from "./pages/main";
// import PolicyCookiePage from "./pages/policies/cookie";
// import PolicyPrivacyPage from "./pages/policies/privary";
// import PolicyWebsitePrivacyPage from "./pages/policies/websitePrivacy";
import WrapperMetaRoutePolicies from "./wrappers/WrapperMetaRoutePolicies";
import WrapperRemoveTrailingSlash from "./wrappers/wrapperRemoveTrailingSlash";
import WrapperTitlePage from "./wrappers/wrapperTitlePage";

const CanvasApp = lazy(() => import("./components/canvas"));

export const dataScroll: IDataScroll = {
  isBlockScroll: true,
  countSlides: 1,
  scrollSlide: 0,
};

const OutletFinishTransition = () => {
  const { onChangeData } = useContext(GlobalContext);

  useEffect(() => {
    materialClouds.uTransition = 0;
    materialClouds.uSlide = 1;
    onChangeData("slide", 1);
  }, []);

  return <Outlet />;
};

const App = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [data, setData] = useState(initDataGlobalContext);
  const onChangeData: TChangeGlobalContext = (key, data) =>
    setData((prev) => ({ ...prev, [key]: data }));

  useEffect(() => {
    if (!!dataScroll.scrollSlide && !data.isBlock) {
      const scale = dataScroll.scrollSlide > 0 ? 1 : -1;

      setData((prev) => ({
        ...prev,
        isBlock: true,
        slide: prev.slide + scale,
      }));
      dataScroll.scrollSlide -= scale;
    }
  }, [data.isBlock, data.needsUpdate]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <GlobalContext.Provider value={{ data, setData, onChangeData }}>
      <div className="App">
        {isMounted && (
          <Suspense fallback={null}>
            <CanvasApp />
          </Suspense>
        )}

        {/* <CanvasApp /> */}
        <div className="wrapperRoutes">
          <WrapperRemoveTrailingSlash />
          <Routes>
            <Route path="" element={<WrapperTitlePage />}>
              <Route path="" element={<MainPage />} />
              <Route element={<OutletFinishTransition />}>
                <Route path="contact" element={<ContactPage />} />
                {/* <Route path="policy" element={<WrapperMetaRoutePolicies />}>
                  <Route path="" element={<Navigate to="/404" />} />
                  <Route path="cookie" element={<PolicyCookiePage />} />
                  <Route path="privacy" element={<PolicyPrivacyPage />} />
                  <Route
                    path="websitePrivacy"
                    element={<PolicyWebsitePrivacyPage />}
                  />
                </Route> */}
                <Route
                  path="404"
                  element={
                    <ErrorPage
                      code={404}
                      nameError="Page not found"
                      text={<TextNotFound />}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="/404" />} />
              </Route>
            </Route>
          </Routes>
        </div>
      </div>
    </GlobalContext.Provider>
  );
};

export default App;
