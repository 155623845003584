import * as Three from "three";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { useContext, useMemo } from "react";
import {
  IUFCloudPoints,
  MaterialClouds,
  TMaterialClouds,
} from "../material/clouds";
import { GlobalContext } from "../../../globalContext";
import { useGetGeometry } from "./utils/useGetGeometry";
import useGetAttribule from "./utils/useGetAttribule";
import { useWindowParams } from "../../../utils/useWindowParams";
import { getColorRGB } from "../../../utils/getColor";
import hoverEffect from "./effects/hover";
import rotationEffect from "./effects/rotation";
import { useDataClouds } from "./utils/useDataClouds";
import { setPostionRotation, useSetting } from "./utils/useSetting";
import transitionEffect from "./effects/transition";

extend({ MaterialClouds });

interface IProps {
  colorPoints?: Three.Color[];
  changeSpeed?: [number, number];
  speedRotation?: number;
  uniformProps?: Partial<IUFCloudPoints>;
}

const getRandomScale = (slide: number) => {
  // if (slide === 1) return 10;
  if (slide === 4) return 0;
  return 7;
};

const limitOpacity = 1;

const initColorPoints = [
  getColorRGB(255, 255, 255),
  // getColorRGB(21, 94, 105),
  // getColorRGB(33, 92, 129),
  // getColorRGB(58, 77, 219),
  // getColorRGB(7, 7, 7),
];

export const materialClouds = new MaterialClouds({
  blending: Three.CustomBlending,
  blendDst: Three.OneMinusSrcAlphaFactor,
  blendSrc: Three.SrcAlphaFactor,
}) as TMaterialClouds;

const Clouds = ({
  colorPoints = initColorPoints,
  changeSpeed = [0.05, 0.3],
  speedRotation = 0.002,
  uniformProps,
}: IProps) => {
  const { camera, raycaster } = useThree();
  const { data, onChangeData } = useContext(GlobalContext);
  const { slide } = data;

  materialClouds.needsUpdate = true;

  const dataClouds = useDataClouds();

  const {
    refPoints,
    refPositionGroup,
    refRotaionObjGroup,
    refRotateGroup,
    dataFrame,
    isMobile,
    threshold,
    pointMesh,
  } = dataClouds;

  const count = useMemo(
    () => (pointMesh[0] + 1) * (pointMesh[1] + 1),
    [pointMesh]
  );

  const size = useWindowParams();

  const geometryData = useGetGeometry(isMobile);
  const attributeData = useGetAttribule({
    obj: refPoints.current,
    count,
    countMove: 2,
    randomScale: getRandomScale(slide),
    colorPoints,
  });

 useSetting({ changeSpeed, geometryData, dataClouds });

  useFrame(({ clock }) => {
    let { uTransition, uOpacity } = materialClouds;

    uOpacity += 0.01;

    if (uOpacity > limitOpacity) uOpacity = limitOpacity;
    if (uOpacity !== limitOpacity) materialClouds.uOpacity = uOpacity;

    if (uTransition > 0) {
      uTransition = transitionEffect({
        obj: refRotaionObjGroup.current,
        changeSpeed,
        rotationStatus: dataFrame.rotation,
        setRotationStatus: (value) => (dataFrame.rotation = value),

        uTransition: dataFrame.transition,
      });
    }

    if (uTransition >= 1) {
      uTransition = 0;
      materialClouds.uSlide = slide;
      materialClouds.uMode = false;
      onChangeData("isBlock", false);
    }

    if (uTransition !== 0)
      setPostionRotation(refPositionGroup, refRotateGroup, size);

    dataFrame.transition = uTransition;
    materialClouds.uTransition = uTransition;
    materialClouds.uTime = clock.elapsedTime;

    hoverEffect({
      raycaster,
      width: size.width,
      camera,
      obj: refPoints.current,
      count,
      threshold: threshold * materialClouds.uScaleHeight,
      isTransition: !!uTransition && uTransition < 0.98,
    });

    rotationEffect({
      obj: refRotaionObjGroup.current,
      rotationStatus: dataFrame.rotation,
      setRotationStatus: (value) => (dataFrame.rotation = value),
      speedRotation,
      isTransition: !!uTransition && uTransition < 0.98,
    });
  });

  return (
    <group ref={refPositionGroup}>
      <group ref={refRotateGroup}>
        <group ref={refRotaionObjGroup}>
          <points ref={refPoints} material={materialClouds}>
            <planeGeometry args={[1, 1, ...pointMesh]}>
              {...Object.entries({ ...geometryData, ...attributeData }).map(
                ([key, value]) => {
                  return (
                    <bufferAttribute attach={`attributes-${key}`} {...value} />
                  );
                }
              )}
            </planeGeometry>
          </points>
        </group>
      </group>
    </group>
  );
};

export default Clouds;
