export const getTitle = (pathname: string) => {
  switch (pathname) {
    case "/": {
      return "Finnected | Connecting Banks with Fintech worldwide.";
    }
    case "/contact": {
      return "Finnected | Contact"
    }
    // case "/policy/cookie": {
    //   return "Chaindentity | Our Cookie Policy"
    // }
    // case "/policy/websitePrivacy": {
    //   return "Chaindentity | Our Website Policy"
    // }
    // case "/policy/privacy": {
    //   return "Chaindentity | Our Privacy Policy"
    // }
    case "/404": {
      return "Finnected | Page not found"
    }
    default: {
      return "Finnected | Connecting Banks with Fintech's worldwide.";
    }
  }
};
